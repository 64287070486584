import React, { useEffect } from "react"
import { Provider } from "react-redux"
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews"
import { linkResolver } from "./lib/linkResolver"
import PageTemplate from "./templates/page"
import PostTemplate from "./templates/Post"
import ModalProvider from "./contexts/ModalContext/ModalProvider"
import { store } from "./store"
import { RolloutFlagProvider } from "./store/rolloutFlag"
import { KetchContextProvider } from "./contexts/KetchContext"
import { ClickIdCookieWriter } from "./lib/QueryToCookieConverter"

const DevelopmentProvider: React.FC<{ element: React.ReactNode }> = ({
  element,
}) => {
  return (
    <Provider store={store}>
      <RolloutFlagProvider>
        <PrismicPreviewProvider
          repositoryConfigs={[
            {
              repositoryName: "cerebral",
              linkResolver,
              componentResolver: componentResolverFromMap({
                page: PageTemplate,
                post: PostTemplate,
              }),
            },
          ]}
        >
          <KetchContextProvider>
            <ClickIdCookieWriter
              clickIds={["ttclid", "fbclid", "gclid", "d", "promo", "msclkid"]}
            />
            <ModalProvider>{element}</ModalProvider>
          </KetchContextProvider>
        </PrismicPreviewProvider>
      </RolloutFlagProvider>
    </Provider>
  )
}

export default DevelopmentProvider
